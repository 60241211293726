import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";
import hamakyoga from "assets/hamakyoga2.png";
import reformer from "assets/reformer_optimized_292x292.jpg";
import yoga from "assets/yoga_optimized_292x292.jpg";
import zumba from "assets/zumba_optimized_292x292.jpg";

function Places() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={8}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Stüdyomuzdaki Dersler"
            container
            circular
            sx={{ mb: 1 }}
          />
          <MKTypography variant="h2" mb={1}>
            Seni Harekete Geçirecek Dersler
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Dilediğin tempoda, uzman eğitmenler eşliğinde bedenini ve zihnini güçlendir.
          </MKTypography>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          {/* Reformer Pilates */}
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={reformer} // kendi belirleyeceğin fotoğraf
                title="Reformer Pilates"
                description="Vücut mekaniğini geliştirirken kaslarını güçlendir. Reformer ekipmanlarıyla denge, esneklik ve dayanıklılığı birlikte keşfet."
                action={{ type: "internal", route: "/", label: "Daha fazla" }}
              />
            </MKBox>
          </Grid>

          {/* Hamak Yoga */}
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={hamakyoga}
                title="Hamak Yoga"
                description="Yerçekimini tersine çevir! Hamakta süzülürken hem eğlen hem esne. Vücudunu ve zihnini hafiflet."
                action={{ type: "internal", route: "/", label: "Daha fazla" }}
              />
            </MKBox>
          </Grid>

          {/* Yoga */}
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={yoga}
                title="Yoga"
                description="Nefesinle buluş, matın üzerinde iç yolculuğuna başla. Esneklik, denge ve iç huzur seni bekliyor."
                action={{ type: "internal", route: "/", label: "Daha fazla" }}
              />
            </MKBox>
          </Grid>

          {/* Zumba */}
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={zumba}
                title="Zumba"
                description="Latin ritimleriyle enerji patlaması! Kalori yak, dans et ve eğlen. Zumba ile spor hiç bu kadar keyifli olmamıştı."
                action={{ type: "internal", route: "/", label: "Daha fazla" }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
