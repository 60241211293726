import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
// eslint-disable-next-line no-unused-vars
import { app, analytics } from "firebaseConfig";
import { AlertProvider } from "providers/AlertProvider";
import { LoaderProvider } from "providers/LoaderProvider";
import { AuthProvider } from "providers/AuthProvider";
import { PlanTypesProvider } from "providers/PlanTypesProvider";
import { MemberProvider } from "providers/MemberProvider";
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

root.render(
  <BrowserRouter>
    <AlertProvider>
      <LoaderProvider>
        <AuthProvider>
          <PlanTypesProvider>
            <MemberProvider>
              <App />
            </MemberProvider>
          </PlanTypesProvider>
        </AuthProvider>
      </LoaderProvider>
    </AlertProvider>
  </BrowserRouter>
);
