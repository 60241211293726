import React, { useRef, useEffect } from "react";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";

import DefaultFooter from "examples/Footers/SimpleFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Information from "pages/LandingPages/Pilates/sections/Information";
import Places2 from "pages/LandingPages/Pilates/sections/Places2";

import footerRoutes from "footer.routes";
import routes from "routes";
import { motion } from "framer-motion";

import "assets/css/tailwind.css";
import photo1 from "assets/banner.png";

function Pilates() {
  const vidRef = useRef();

  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.load();
      vidRef.current.setAttribute("muted", "");
      vidRef.current.defaultMuted = true;
      vidRef.current.playsInline = true;
      vidRef.current.play();
    }
  }, []);

  return (
    <>
      <DefaultNavbar routes={routes} sticky />

      <div
        style={{
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={photo1}
          alt="Pilates Banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // taşmadan alanı doldur
            objectPosition: "center", // ortala
            display: "block",
          }}
        />
      </div>

      {/* Card Content Section - Animated */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
        viewport={{ once: true }}
      >
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -4,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Information />
          <Places2 />
        </Card>
      </motion.div>

      <MKBox pt={3} px={1} mt={3}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Pilates;
