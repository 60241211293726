import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Fulya Aksoy Pilates components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }}>
      <Container>
        <Grid
          container
          item
          justifyContent="left"
          xs={12}
          lg={12}
          sx={{ mx: "auto", pb: 3, textAlign: "left" }}
        >
          <MKTypography variant="h4" color="text" sx={{ fontWeight: "bold", mb: 2 }}>
            Sevgili Ziyaretçimiz,
          </MKTypography>

          <MKTypography variant="body1" color="text" paragraph>
            Sağlıklı, enerjik ve dengeli bir yaşama adım atmanın şimdi tam zamanı!
            <br /> <strong>Fulya Aksoy Pilates Stüdyosu</strong> olarak, bedeninizi ve zihninizi
            yeniden keşfetmeniz için sizi bekliyoruz.
          </MKTypography>

          <MKTypography variant="h6" color="text" sx={{ fontWeight: "bold", mb: 2 }}>
            İşte bize katılmanız için birkaç güçlü neden:
          </MKTypography>

          <Stack spacing={3}>
            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>💡 Özel ve Bireysel Yaklaşım:</strong> <br />
                  Her bireyin farklı ihtiyaçları olduğuna inanıyoruz. Uzman eğitmenlerimiz,
                  hedeflerinize uygun kişisel yönlendirme ile sizinle birebir ilgileniyor.
                </MKTypography>
              }
            />

            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>🧘‍♀️ Zengin Ders Seçenekleri:</strong> <br />
                  Sadece Reformer Pilates değil! Artık stüdyomuzda <strong>Hamak Yoga</strong>,{" "}
                  <strong>Yoga</strong>
                  ve ritmiyle sizi harekete geçiren <strong>Zumba</strong> dersleri de mevcut.
                  Vücudunuzu hem güçlendirmek hem de esnetmek için en doğru yerdesiniz.
                </MKTypography>
              }
            />

            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>🏋️‍♀️ Modern Ekipman ve Profesyonellik:</strong> <br />
                  Tam donanımlı stüdyomuzda, en güncel Pilates ekipmanları eşliğinde, alanında uzman
                  sertifikalı eğitmenlerle çalışacaksınız.
                </MKTypography>
              }
            />

            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>❤️ Motivasyon ve Topluluk Desteği:</strong> <br />
                  Fulya Aksoy Pilates, sadece bir stüdyo değil, aynı zamanda birbirini destekleyen
                  pozitif bir topluluk. Her ders, yeni bir enerji ve ilham kaynağı!
                </MKTypography>
              }
            />

            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>🕒 Esnek Ders Saatleri:</strong> <br />
                  Yoğun bir programınız olsa bile endişelenmeyin! Esnek ders saatleri ve çeşitli
                  programlarımızla yaşam tarzınıza uygun bir plan oluşturabiliriz.
                </MKTypography>
              }
            />

            <AboutUsOption
              content={
                <MKTypography variant="body1" color="text">
                  <strong>🎁 Ücretsiz Deneme Dersi:</strong> <br />
                  İlk dersinizi ücretsiz olarak alın ve stüdyomuzdaki atmosferi deneyimleyin. Bizi
                  keşfedin ve ne kadar fark yaratabileceğimizi görün!
                </MKTypography>
              }
            />
          </Stack>

          <MKTypography variant="body1" color="text" paragraph>
            Sağlığınıza yatırım yapma zamanı geldi. Hemen bugün bize katılın ve hayalinizdeki
            sağlıklı yaşama adım atın!
          </MKTypography>

          <MKTypography variant="body1" color="text" paragraph>
            Sevgiler,
            <br />
            Fulya Aksoy
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
